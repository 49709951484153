.root {
	color: var(--color-neutral-6);
	border-bottom: 4px solid transparent;
	cursor: pointer;
	-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently */
	&[data-is-active="true"]{
		color: var(--color-primary-5);
		border-bottom: 4px solid var(--color-primary-5);
	}
}
