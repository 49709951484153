@import "Themes/constants.scss";

.root {
	padding-top: 8px;
	max-height: 310px;
	overflow: auto;

	@media (max-width: $screen-s) {
		max-height: 500px;
	}

	table {
		width: 100%;
		text-align: left;
		border-collapse: collapse;
		table-layout: fixed;
		thead {
			border-bottom: 1px solid #cbd5e1;
			th {
				&:first-of-type {
					padding-left: 24px;
				}
				font-family: var(--font-primary);
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 24px;
				color: var(--color-neutral-8);
			}
		}

		tbody {
			tr {
				border-bottom: 1px solid #cbd5e1;
				font-family: var(--font-primary);
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 24px;
				color: var(--color-neutral-6);
				&:last-of-type {
					border: none;
				}
				.price-container {
					display: flex;
					align-items: center;
					gap: 8px;
					height: 71px;
				}

				.offer-from {
					font-family: var(--font-primary);
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 24px;
					color: var(--color-primary-5);
					text-decoration: none;
				}
				td {
					&:first-of-type {
						padding-left: 24px;
					}
					&:last-of-type {
						text-align: right;
						padding-right: 24px;
					}
					padding: 16px 0;
				}
			}
		}
	}

	.offers-container-responsive {
		display: flex;
		flex-direction: column;
		.offers-first-line {
			padding: 8px 24px;
			font-family: var(--font-primary);
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			color: var(--neutral-color-8);
		}

		.separator {
			height: 1px;
			background: var(--color-neutral-4);
		}

		.offer-container-responsive {
			.offer-responsive {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 22px 24px;
				.price-responsive {
					width: 76px;
					display: flex;
					gap: 8px;
					font-family: var(--font-primary);
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 24px;
					color: var(--color-neutral-8);
				}
			}

			.offer-details {
				&[data-is-bid="true"] {
					height: 137px;
				}
				&[data-is-bid="false"] {
					height: 97px;
				}

				&[data-visible="false"] {
					height: 0px;
				}

				overflow: hidden;
				transition: height 300ms $custom-easing;
				&::before {
					content: "";
					display: block;
					height: 1px;
					background: var(--color-neutral-4);
				}
				display: flex;
				flex-direction: column;
				padding: 0px 24px 16px 24px;
				gap: 16px;

				.offer-details-line {
					display: flex;
					justify-content: space-between;

					> p {
						margin: 0;
					}

					.offer-details-left {
						font-family: var(--font-primary);
						font-style: normal;
						font-weight: 400;
						font-size: 16px;
						line-height: 24px;
						color: var(--color-neutral-8);
					}

					.offer-details-right {
						font-family: var(--font-primary);
						font-style: normal;
						font-weight: 500;
						font-size: 16px;
						line-height: 24px;
						color: var(--color-neutral-6);
					}

					.offer-details-from {
						font-family: var(--font-primary);
						font-style: normal;
						font-weight: 500;
						font-size: 16px;
						line-height: 24px;
						color: var(--color-primary-5);
						text-decoration: none;
					}
				}
			}

			&::after {
				content: "";
				height: 1px;
				display: block;
				background: var(--color-neutral-4);
			}

			&:last-of-type {
				&::after {
					height: 0px;
				}
			}
		}
	}
}
