@import "Themes/constants.scss";
@import "Themes/fonts.scss";
@import "Themes/modes.scss";
@import "Themes/variables.scss";

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #F9FAFB;
	font-family: var(--font-primary);
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	font-size: 0;
	overflow-x: hidden;
	max-width: 100vw;
}

* {
	box-sizing: border-box;
}
