@import "Themes/constants.scss";

.root {
	display: flex;
	align-items: center;
	gap: 8px;
	height: 40px;
	width: 33vw;
	max-width: 438px;

	.input {
		background: var(--color-white);
		border: 1px solid var(--color-neutral-4);
		border-radius: 4px;
		flex: 1;
		padding: 10px 12px;

		font-family: var(--font-primary);
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;

		::placeholder {
			color: (--color-neutral-5);
		}
	}

	@media screen and (max-width: $screen-s) {
		width: 76vw;
		max-width: 350px;
		flex-wrap: wrap;
		justify-content: center;

	}
}
