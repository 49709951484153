.root {
	color: initial;
	font-weight: 600;
	display: flex;
	align-items: center;
	img {
		width: 48px;
		height: 48px;
	}
	.nft-name {
		margin-left: 16px;
	}
}

