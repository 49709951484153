@import "Themes/constants.scss";

:root {
	--content-max-width: 2160px;
	--template-margin: auto;
	--content-padding: 32px;

	--font-primary: "Inter", sans-serif;
	--font-secondary: "Source Sans Pro", sans-serif;
}
