@import "Themes/constants.scss";
.root {
	color: var(--color-neutral-6);
	font-weight: 500;
	font-size: 14px;
	display: grid;
    grid-template-columns: repeat(auto-fit,minmax(125px,1fr));
	align-content: center;
	height: 80px;
	border-bottom: 1px solid #cbd5e1;
	div{
		align-self: center;
	}
	.nft-identificator {
		color: initial;
		font-weight: 600;
		display: flex;
		align-items: center;
		img {
			width: 48px;
			height: 48px;
		}
		.nft-name {
			margin-left: 16px;
		}
	}
	
	.eth-price{
		color: initial;
	}
	.user-name {
		color: var(--color-primary-5);
	}

}