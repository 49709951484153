@import "Themes/constants.scss";

.root {
	@media (min-width: $screen-m) {
		display: none;
	}

	z-index: 2;
	position: fixed;
	top: 0;
	width: 100vw;
	height: 100vh;
	max-height: 100vh;
	height: 100vh;
	margin-top: 80px;
	.nav {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		height: 100%;
		padding-bottom: 24px;
		opacity: 1;
		overflow: auto;
		position: relative;
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
		font-size: 16px;
		max-height: calc(100vh - 80px);
		overflow: auto;
		z-index: 5;
		transition: transform 250ms $custom-easing, opacity 350ms $custom-easing;
		transform: translateX(0);
		background: var(--color-generic-white);

		@media (max-width: $screen-s) {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}

		.link-image-container {
			display: flex;
			gap: 12px;
			padding: 10px 15px;

			.link {
				font-family: var(--font-primary);
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 20px;
				color: var(--color-neutral-7);
				text-decoration: none;
			}
		}

		.btn-container {
			display: none;

			@media (max-width: $screen-s) {
				display: flex;
				justify-content: center;
			}
		}
		.separator {
			// grid-column: 1/4;
			// height: 1px;
			// margin: 16px auto 0 auto;
			// background: var(--color-neutral-3);
			// width: calc(100% - 32px);

			// @media (max-width: $screen-s) {
			// 	align-self: center;
			// }
			/* Neutral/Neutral 3 */
			border-bottom: 1px solid #e2e8f0;
			margin-top: 8px;
			margin-bottom: 8px;

			@media (min-width: $screen-s) {
				display: none;
			}
		}

		.nav-bottom {
			display: flex;
			justify-content: space-between;
			grid-column: 1/4;
			padding: 0 16px;
			@media (max-width: $screen-s) {
				display: block;
			}

			.btn-container-tablet {
				@media (max-width: $screen-s) {
					display: none;
				}
			}
			> div button {
				margin: auto;
				@media (max-width: $screen-s) {
					margin-top: 24px;
				}
			}
		}
	}

	.shadow {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 1;
		position: absolute;
		opacity: 1;
		transition: opacity 300ms $custom-easing;
	}

	&[status="closed"] {
		pointer-events: none;
		.nav {
			transform: translateX(-100%);
			opacity: 0;
		}

		.shadow {
			opacity: 0;
		}
	}

	.top-menu-burger {
		align-self: flex-start;
		padding: 0px 32px;
	}
}

