@import "Themes/constants.scss";

.root {
	display: flex;
	flex-direction: column;
	gap: 32px;

	font-family: var(--front-primary);
	font-style: normal;
	font-weight: 600;
	color: var(--color-neutral-8);
	padding: 8px;

	min-width:326px;

	.title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 20px;
		line-height: 28px;
		border-bottom: 1px solid var(--color-neutral-4);
		padding-bottom: 22px;
		cursor: pointer;

		.left {
			display: flex;
			align-items: center;
			gap: 11px;
		}
	}
}

.closed-filter {
	cursor: pointer;
	border-right: 1px solid var(--color-neutral-4);
	height: 100%;
	padding: 32px;
	padding-top: 70px;
}

