@import "Themes/constants.scss";

.root {
	width: 443px;
	background: var(--color-generic-white);
	box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.11);
	border-radius: 5px;

	@media (max-width: 444px) {
		width: calc(100vw - 16px);
	}
	transform: translateX(120%);

	transition: all 200ms $custom-easing;
	&[data-mounted="true"] {
		transform: translateX(0);
	}
	.loadbar {
		height: 3px;
		background: var(--color-neutral-5);
		border-radius: 5px;

		animation: loadbar-animation var(--data-duration) linear;
	}

	@keyframes loadbar-animation {
		from {
			width: 100%;
		}
		to {
			width: 0;
		}
	}
	.toast-container {
		padding: 16px;

		.title-container {
			font-family: var(--font-primary);
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
			color: var(--color-neutral-8);

			display: flex;
			align-items: center;
			justify-content: space-between;

			.cross {
				cursor: pointer;
			}
		}

		.text-container {
			font-family: var(--font-primary);
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			color: var(--color-neutral-6);
			margin-top: 8px;
		}

		.button-container {
			margin-top: 16px;
		}
	}
}
