.root {
	display: flex;
	justify-content: center;
	border-radius: 5px;
	border: 1px solid;
	box-sizing: border-box;
	height: fit-content;
	display: flex;
	align-items: center;
	gap: 6px;
	background: transparent;
	font-family: var(--font-primary);
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	white-space: nowrap;

	user-select: none;
	cursor: pointer;

	&[variant="primary"] {
		color: var(--color-neutral-1);
		background-color: var(--color-primary-5);
		border-color: var(--color-primary-5);
		&:hover {
			border-color: var(--color-primary-6);
			background-color: var(--color-primary-6);
		}
		&:active {
			border-color: var(--color-primary-7);
			background-color: var(--color-primary-7);
		}
		&:disabled {
			border-color: var(--color-primary-3);
			background-color: var(--color-primary-3);
			pointer-events: none;
		}
	}
	&[variant="ghost"] {
		color: #4b4efc;
		border: none;
		background: #f5f5ff;
	}

	&[variant="tertiary"] {
		color: var(--color-neutral-5);
		border-color: var(--color-neutral-5);
		background: transparent;
		&:hover {
			color: var(--color-neutral-6);
			border-color: var(--color-neutral-6);
			background-color: var(--color-neutral-6);
			background: var(--color-neutral-1);
		}
		&:active {
			color: var(--color-neutral-6);
			border-color: var(--color-neutral-6);
			background-color: var(--color-neutral-2);
		}
		&:disabled {
			color: var(--color-neutral-3);
			border-color: var(--color-neutral-5);
			background: transparent;
			pointer-events: none;
		}
	}

	&[sizing="s"] {
		font-size: 14px;
		line-height: 20px;
		padding: 8px 16px;
	}

	&[sizing="m"] {
		font-size: 16px;
		line-height: 24px;
		padding: 8px 20px;
	}

	&[sizing="l"] {
		font-size: 18px;
		line-height: 28px;
		padding: 10px 24px;
	}
	&[sizing="xl"] {
		justify-content: center;
		font-size: 18px;
		line-height: 28px;
		padding: 10px 24px;
		width: 100%;
		gap: 16px;
	}
	&[sizing="cube"] {
		font-size: 14px;
		line-height: 20px;
		padding: 8px 8px;
	}
}

