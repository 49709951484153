@import "Themes/constants.scss";

.root {
	display: flex;
	flex-direction: column;
	gap: 32px;

	font-family: var(--front-primary);
	font-style: normal;
	font-weight: 600;
	color: var(--color-neutral-8);

	.filter {
		display: flex;
		flex-direction: column;
		gap: 16px;

		.subtitle {
			font-size: 16px;
			line-height: 24px;
		}

		.status {
			display: flex;
			gap: 8px;

			> button {
				flex: 1;
			}
		}

		.dropdown-container {
			> :nth-child(1) {
				border-top: 1px solid var(--color-neutral-3);
			}
			> :nth-child(n) {
				border-bottom: 1px solid var(--color-neutral-3);
			}
			.attribute {
				font-weight: 400;
				font-size: 12px;
				line-height: 18px;
				padding: 8px;
			}
		}

		.min-max {
			display: flex;
			align-items: center;
			gap: 16px;
			width: 100%;

			.to {
				font-weight: 400;
				font-size: 14px;
				line-height: 17px;
				color: var(-color-neutral-8);
			}

			> input {
				width: 100%;
				background: var(--color-generic-white);
				border: 1px solid var(--color-neutral-4);
				border-radius: 4px;
				height: 40px;
				padding: 10px 12px;

				&::-webkit-inner-spin-button {
					display: none;
				}

				&:focus-visible {
					outline-color: var(--color-primary-5);
					outline-style: solid;
					border: none;
				}
			}
		}
	}
}

