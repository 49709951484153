@import "Themes/constants.scss";

.root {
	position: absolute;
	top: 64px;
	right: 0;
	width: 274px;
	display: flex;
	flex-direction: column;

	box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.11);
	border-radius: 0px 0px 0px 5px;

	z-index: 1;

	background-color: var(--color-generic-white);

	max-height: calc(100vh - 80px);
	overflow: scroll;
	&[status="closed"] {
		display: none;
	}

	.links-container {
		display: flex;
		flex-direction: column;
	}

	.links-container-mobile {
		display: none;
		flex-direction: column;

		@media (max-width: $screen-m) {
			display: flex;
		}
	}

	.link {
		display: flex;
		align-items: center;
		gap: 8px;

		text-decoration: none;
		font-family: var(--font-primary);
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;

		height: 60px;
		padding: 0 16px;

		user-select: none;
		cursor: pointer;

		color: var(--color-neutral-7);

		&:hover {
			background: var(--color-neutral-1);
		}
	}

	.separator {
		padding-top: 1px;
		background: var(--color-neutral-3);
		margin: 8px 16px;
	}

	.separator-mobile {
		padding-top: 1px;
		background: var(--color-neutral-3);
		margin: 8px 16px;
		display: none;

		@media (max-width: $screen-m) {
			display: block;
		}
	}
}
