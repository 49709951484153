@import "Themes/constants.scss";

.root {
	position: absolute;
	width: 100%;
	max-height: calc(100vh - 80px);
	overflow-y: auto;
	background: white;
	top: 75px;
	left: 0%;
	box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.15);
	border-radius: 8px;

	@media (max-width: $screen-m) {
		width: 100%;
		z-index: 6;
		top: 60px;
		border-radius: 0 0 8px 8px;
		transform: none;
		left: 0;
		box-shadow: none;
		max-height: calc(100vh - 150px);
	}

	&[data-open="closed"] {
		display: none;
	}

	.all-results-line {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 12px;

		font-family: var(--font-primary);
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		color: var(--color-neutral-7);
		cursor: pointer;
	}
}
