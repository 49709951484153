@import "Themes/constants.scss";
.root {
	min-width: 270px;
	max-width: 320px;
	color: var(--color-neutral-6);
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	align-content: center;
	box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.05);
	border-radius: 5px;
	overflow: hidden;
	margin: auto;

	div {
		align-self: center;
	}
	.nft-image {
		width: 100%;
	}
	
	.text-plus-symbol {
		display: flex;
		align-items: center;
		gap: 8px;
		.copy-icon {
			margin-left: 8px;
		}
	}

	.nft-data {
		display: grid;
		grid-template-columns: 1fr 1fr;
		margin-bottom: 24px;
		.nft-data-content {
			&.nft-data-label {
				color: initial;
			}
			div {
				line-height: 40px;
			}
			
			.eth-price {
				color: initial;
			}
			.user-name {
				color: var(--color-primary-5);
			}
			.nft-name {
				font-weight: 600;
				font-size: 14px;
				color: initial;
			}
		}
	}
	.low-card{
		padding: 16px 24px;
	}
}

