@import "Themes/constants.scss";

.root {
	display: flex;
	padding: 16px 42px;
	flex-wrap: wrap;
	gap: 50px;
	justify-content: space-between;

	@media (max-width: $screen-m) {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		column-gap: 20px;
		row-gap: 16px;
	}

	@media (max-width: $screen-s) {
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding: 16px;
	}

	.details-block {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		.details-label {
			margin: 0;
			font-family: var(--font-primary);
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			color: var(--color-neutral-8);
		}
		.details-info {
			margin: 0;
			font-family: var(--font-primary);
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			color: var(--color-neutral-6);

			display: flex;
			align-items: center;
			gap: 8px;
		}

		.metadatas {
			color: var(--color-primary-5);
			text-decoration: none;
		}
	}
}
