.profile-container {
	display: flex;
	gap: 8px;
	align-items: center;

	.profile-button {
		cursor: pointer;
	}
}

