@import "Themes/constants.scss";

.root {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding-right: 112px;
	padding-left: 112px;
	padding-top: 32px;
	padding-bottom: 32px;

	font-family: var(--font-primary);
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	background: var(--color-neutral-1);
	@media screen and (max-width: $screen-m) {
		padding-right: 16px;
		padding-left: 16px;
	}

	.content {
		display: flex;
		justify-content: space-between;
		padding-bottom: 64px;
		border-bottom: 1px solid var(--color-neutral-3);
		gap: 100px;

		@media screen and (max-width: $screen-m) {
			padding-right: 32px;
			padding-left: 32px;
		}

		@media screen and (max-width: $screen-s) {
			flex-direction: column;
			align-items: center;
			gap: 40px;
		}

		.left {
			display: flex;
			flex-direction: column;
			gap: 32px;
			width: 100%;

			.nav-container {
				display: flex;
				gap: 24px;
				.route {
					color: var(--color-neutral-6);
					text-decoration: none;
					white-space: nowrap;

					&active-route {
						color: var(--color-neutral-6);
					}
				}

				@media screen and (max-width: 340px) {
					flex-wrap: wrap;
				}
			}
		}

		.right {
			display: flex;
			flex-direction: column;
			gap: 16px;
			width: 100%;
			.title-newsletter {
				color: var(--color-neutral-8);
			}
		}
	}

	.down-part {
		display: flex;
		justify-content: space-between;
		padding-top: 32px;
		gap: 32px;

		@media screen and (max-width: $screen-m) {
			padding-right: 32px;
			padding-left: 32px;
		}

		@media screen and (max-width: $screen-s) {
			flex-direction: column-reverse;
			align-items: center;
		}

		.social-medias {
			display: flex;
			gap: 24px;
		}

		.policy {
			display: flex;
			gap: 32px;
			color: var(--color-neutral-6);
			white-space: nowrap;

			a {
				text-decoration: none;
				color: var(--color-neutral-6);
			}
		}
	}
}
