@import "Themes/constants.scss";
.root {
	display: flex;
	gap: 32px;
	margin-top: 24px;

	@media (max-width: $screen-m) {
		display: none;
	}
	.price-element {
		.price-label {
			margin: 0;
			font-family: var(--font-primary);
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
			color: var(--color-neutral-6);
		}
		.price-container {
			display: flex;
			align-items: center;
			gap: 8px;
			.currency-logo {
			}
			.price {
				margin: 0;
				font-family: var(--font-primary);
				font-style: normal;
				font-weight: 700;
				font-size: 28px;
				line-height: 34px;
				letter-spacing: -0.02em;
				color: var(--color-neutral-8);
			}
			.price-in-fiat {
				margin: 0;
				font-family: var(--font-primary);
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 20px;
				color: var(--color-neutral-6);
				align-self: flex-end;
			}
		}
	}
}
