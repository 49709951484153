@import "../Base/classes.module.scss";

.root {
	font-family: var(--font-primary);
	font-style: normal;
	font-size: 16px;
	padding: 0 70px;
	margin: 0 auto;

	h1 {
		font-size: 24px;
		margin: 24px 0;
	}

	.proposal-page {
		display: grid;
		grid-template-columns: 2fr 1fr;
		gap: 24px;
	}

	h2 {
		font-weight: 700;
		font-size: 20px;
		line-height: 28px;
		color: #111827;
		display: inline-block;
		margin: 0 0 24px 0;
	}

	.proposal-card {
		padding: 24px;
		width: 100%;
		margin-bottom: 24px;
		background: #ffffff;
		border: 1px solid #e5e7eb;
		border-radius: 8px;
	}

	.subgrid {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 24px;
	}

	.left {
		float: left;
	}

	.subcenter {
		text-align: center;

		button {
			width: 100%;
		}
	}

	.staking-amount {
		margin: 24px auto;
		width: 180px;
		display: grid;
		grid-template-columns: 20px auto;
		gap: 10px;
	}

	.amount-value {
		font-family: "Inter";
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 20px;
	}

	.staking-input-input-title {
		font-size: 14px;
		margin-bottom: 10px;
	}

	.staking-input-input-container {
		float: right;
	}

	.staking-input-grid {
		// display: grid;
		// grid-template-columns: 300px 80px;
		// gap: 10px;

		input {
			float: left;
		}

		button {
			float: right;
		}
	}

	.staking-input-input {
		margin-right: 10px;
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 10px 12px;
		gap: 12px;
		width: 300px;
		height: 42px;
		background: #ffffff;
		border: 1px solid #d1d5db;
		box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
		border-radius: 6px;
		flex: none;
		order: 1;
		align-self: stretch;
		flex-grow: 0;

		&::placeholder {
			font-family: var(--font-primary);
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			color: var(--color-neutral-5);
		}
	}

	.new-proposal {
		float: right;
		display: inline-block;
		margin: 16px 0 0 0;

		> a {
			text-decoration: none;
		}
	}

	.proposal-link {
		text-decoration: none;
		width: 100%;
	}

	.proposal-author {
		font-size: 12px;
		font-weight: 600;
		line-height: 20px;
		color: #4b5563;
	}

	.proposal-title {
		font-weight: 700;
		font-size: 40px;
		line-height: 48px;
		color: #111827;
		display: inline-block;
		vertical-align: middle;
	}

	.proposal-description {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: #6b7280;
		margin: 16px 0;
	}

	.proposal-status-open {
		font-size: 14px;
		font-weight: 700;
		line-height: 17px;
		color: #008baa;
		float: right;
	}

	.proposal-status-closed {
		font-size: 14px;
		font-weight: 700;
		line-height: 17px;
		color: #ed306a;
		float: right;
	}

	.proposal-vote {
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 28px;
		color: #111827;
		margin: 50px 0 20px 0;
	}

	.proposal-votes {
		button {
			display: inline-block;
			margin-right: 20px;
		}
	}

	.proposal-level {
		display: inline-block;
	}

	.proposal-level-soft {
		padding: 0 10px;
		height: 24px;
		margin: 0 0 0 10px;
		background: rgba(170, 92, 0, 0.12);
		border-radius: 25px;
		font-weight: 600;
		font-size: 12px;
		line-height: 20px;
		display: flex;
		align-items: center;
		text-align: center;
		font-feature-settings: "salt" on;
		color: #e68a00;
	}

	.proposal-level-hard {
		padding: 0 10px;
		height: 24px;
		margin: 0 0 0 10px;
		background: rgba(105, 0, 170, 0.12);
		border-radius: 25px;
		font-weight: 600;
		font-size: 12px;
		line-height: 20px;
		display: flex;
		align-items: center;
		text-align: center;
		font-feature-settings: "salt" on;
		color: #c545c8;
	}

	.proposal-choice {
		width: 100%;
		height: 36px;
		margin: 0 0 12px 0;
		padding-left: 20px;
		background: #f9fafb;
		border-radius: 6px;
		font-weight: 500;
		font-size: 14px;
		line-height: 36px;
		color: #111827;
		cursor: pointer;
	}

	.proposal-send-vote {
		width: 100%;
		height: 40px;
		line-height: 40px;
		text-align: center;
		background: #4b4efc;
		border-radius: 6px;
		font-weight: 500;
		font-size: 16px;
		cursor: pointer;
		color: #ffffff;
	}

	.proposal-detail {
		display: grid;
		grid-template-columns: 1fr 2fr;
	}

	.proposal-detail-title {
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		color: #4b5563;
	}

	.proposal-detail-value {
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		color: #111827;

		a {
			color: #111827;
		}
	}

	.proposal-results-live {
		height: 20px;
		padding: 0 10px;
		background: #ef4444;
		border-radius: 13px;
		font-weight: 500;
		font-size: 12px;
		line-height: 20px;
		display: flex;
		align-items: center;
		font-feature-settings: "salt" on;
		color: #f9fafb;
		display: inline-block;
		margin: 0px 0px 0px 10px;
		vertical-align: text-bottom;
	}

	.proposal-vefdao {
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: #6b7280;
		display: inline-block;
		float: right;
	}

	.proposal-results {
	}

	.proposal-results-choice {
		width: 100%;
		height: 36px;
		margin: 0 0 12px 0;
		background: #f9fafb;
		border-radius: 6px;
		font-weight: 500;
		font-size: 14px;
		line-height: 36px;
		color: #111827;
		position: relative;
	}

	.proposal-results-choice-grid {
		padding: 0 20px;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}

	.proposal-results-choice-bar {
		position: relative;
		border-radius: 6px 0 0 6px;
		background: #e5e7eb;
		width: 100%;
		height: 36px;
	}

	.proposal-results-choice-title {
		font-weight: 500;
		font-size: 14px;
		line-height: 36px;
		color: #111827;
	}

	.proposal-results-choice-tokens {
		font-weight: 500;
		font-size: 12px;
		line-height: 36px;
		color: #6b7280;
	}

	.proposal-results-choice-percent {
		text-align: right;
		font-weight: 500;
		font-size: 12px;
		line-height: 36px;
		color: #111827;
	}
}

